export const appRoutes = {
  label: "Mind Pulse",
  children: [
    {
      name: "Dashboard",
      icon: "chart-pie",
      to: "/admin/dashboard",
      active: true,
    },
    {
      name: "Page Inner Titles",
      icon: "question-circle",
      to: "/admin/page-titles",
      active: true,
    },
  ],
};

export const userRoutes = {
  label: "Users & Businesses",
  children: [
    {
      name: "Businesses & Organisations",
      icon: "user",
      to: "/admin/businesses",
      active: true,
    },
    {
      name: "Users",
      icon: "users",
      to: "/admin/users",
      active: true,
    },
  ],
};

export const recipeRoutes = {
  label: "Recipes",
  children: [
    {
      name: "Recipes",
      icon: ["fab", "trello"],
      to: "/admin/recipes",
      active: true,
    },
  ],
};

export const exerciseRoutes = {
  label: "Exercise",
  children: [
    {
      name: "Exercise Routines",
      icon: "puzzle-piece",
      to: "/admin/exercise-routines",
      active: true,
    },
  ],
};

export const audioRoutes = {
  label: "Audio Content",
  children: [
    {
      name: "Mindfulness",
      icon: ["fab", "trello"],
      to: "/admin/mindfulness",
      active: true,
    },
    {
      name: "Virtual Commute",
      icon: ["fab", "trello"],
      to: "/admin/virtual-commute",
      active: true,
    },
  ],
};

export const generalFunctionalityRoutes = {
  label: "General Functionality",
  children: [
    // {
    //   name: "Support Connect",
    //   icon: "thumbtack",
    //   to: "/admin/support-connect",
    //   active: true,
    // },
  ],
};

export default [
  appRoutes,
  userRoutes,
  recipeRoutes,
  exerciseRoutes,
  audioRoutes,
  generalFunctionalityRoutes,
];
