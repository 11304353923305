import React, { useEffect, useState } from "react";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { set, useForm } from "react-hook-form";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import {
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import EmployeeService from "services/employee/employee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import manager from "../../services/manager/manager";
import EatwellService from "../../services/recipes/recipes";
import FalconCloseButton from "components/common/FalconCloseButton";

import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Businesses = () => {
  const dispatch = useDispatch();
  const [dataTableData, setDataTableData] = useState([]);
  const [view, setView] = useState(false);
  const [getGender, setGender] = useState("");
  const [fileDocList, setFileDocList] = useState("");
  const [image, imageStore] = useState("");

  const [showSubscription, setShowSubscription] = useState(false);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedPlanName, setSelectedPlanName] = useState(
    "63e8d49d148d1035e10bf64e"
  );
  const [selectedPlanSeats, setSelectedPlanSeats] = useState(0);
  const [usedSeats, setUsedSeats] = useState(0);
  const [selectedPlanPricePerUser, setSelectedPlanPricePerUser] = useState(0);
  const [selectedPlanDate, setSelectedPlanDate] = useState(new Date());
  const [selectedPlanFrequency, setSelectedPlanFrequency] = useState("monthly");

  const handleClose = () => {
    reset({ keepDirtyValues: true }, { keepIsValid: true });
    setView(false);
  };

  const getData = async () => {
    dispatch(manager.getManager())
      .then((res) => {
        console.log(res.data);
        setDataTableData(res.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getData();
  }, []);

  const handleView = (id) => {
    var data = {
      id: id,
    };
    dispatch(EmployeeService.edit_manager(data))
      .then((res) => {
        setView(true);
        setGender(res.data.gender);
        setValue("email", res.data.email);
        setValue("mobile", res.data.mobile);
        setValue("firstname", res.data.firstname);
        setValue("lastname", res.data.lastname);
        setValue("id", res.data._id);
        setValue("manager_id", res.data.manager_id);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const showSubscriptionPanel = (data) => {
    console.log(data);
    setShowSubscription(!showSubscription);
  };

  const handleChangeImageUpload = (event) => {
    var image_pre = event.target.files[0];

    dispatch(EatwellService.upload_file(image_pre))
      .then((res) => {
        if (res.status == "200") {
          imageStore(res.data.file_url);
        }
      })
      .catch((errors, statusCode) => {
        console.log(errors.data.message, "danger");
      });
  };

  const update_manager = (data) => {
    if (getGender != "") {
      data["gender"] = getGender;
    }
    if (image != "") {
      data["profile_image"] = image;
    }
    dispatch(EmployeeService.update_employee(data))
      .then((res) => {
        console.log(res);
        // getData();
        handleClose();
      })
      .catch((errors) => {
        console.log(errors.data.message);
      });
  };

  const subscribe_to_plan = () => {
    const data = {
      plan_user: selectedUser,
      plan_name: selectedPlanName,
      plan_seats: selectedPlanSeats,
      used_seats: usedSeats,
      plan_price: selectedPlanPricePerUser,
      plan_date: selectedPlanDate,
      frequency: selectedPlanFrequency,
    };
    console.log("PLAN DATA: ", data);

    dispatch(manager.setupPlanPermissions(data))
      .then((res) => {
        toast.success("Plan Updated Successfully!");
        getData();
        handleSubscriptionClose();
      })
      .catch((errors) => {
        console.log(errors.data.message);
      });
  };

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are 0-based
    const year = dateObj.getFullYear();

    return `${getWithOrdinal(day)} of each month`;
  }

  function formatYearDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "long" });
    const year = dateObj.getFullYear();

    return `${getWithOrdinal(day)} of ${month} `;
  }

  function getWithOrdinal(n) {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  const handleSubscriptionClose = () => {
    setShowSubscription(false);
  };

  const columns = [
    {
      accessor: "_id",
      Header: "No",
      Cell: (rowData) => {
        return parseInt(rowData.row.id) + 1;
      },
    },
    {
      accessor: "fullname",
      Header: "Full Name",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "company_name",
      Header: "Company Name",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <p>
              {row?.company
                ? row?.company?.company_name ?? "Not on supported plan"
                : "No Company"}
            </p>
          </>
        );
      },
    },
    {
      accessor: "mobile",
      Header: "Phone",
    },
    {
      accessor: "plan_name",
      Header: "Plan Name",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <p>
              {row?.subscription_plan
                ? row?.subscription_plan?.name ?? "No Plan"
                : "No Company"}
            </p>
          </>
        );
      },
    },
    {
      accessor: "seats",
      Header: "Available Seats",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <p>{row?.company ? row?.company?.seats ?? 0 : 0}</p>
          </>
        );
      },
    },
    {
      accessor: "usedSeats",
      Header: "Used Seats",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <p>{row?.company ? row?.company?.usedSeats ?? 0 : 0}</p>
          </>
        );
      },
    },
    {
      accessor: "pricePerUser",
      Header: "Price Per User",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <p>${row.company ? row.company.perUserPrice ?? 0 : 0}</p>
          </>
        );
      },
    },
    {
      accessor: "charge_date",
      Header: "Charge Date",
      Cell: (rowData) => {
        const row = rowData.row.original;
        let renewalText =
          row.company.planFrequency && row.company.planFrequency == "monthly"
            ? `${
                row.company.planFrequency.charAt(0).toUpperCase() +
                row.company.planFrequency.slice(1)
              } on ${formatDate(row.company.companyPlanStartDate)}`
            : row.company.planFrequency
            ? `Annually on ${formatYearDate(row.company.companyPlanStartDate)}`
            : "No Frequency Set";
        return (
          <>
            <p>{renewalText}</p>
          </>
        );
      },
    },
    {
      accessor: "stripe_id",
      Header: "Managed",
      Cell: (rowData) => {
        const row = rowData.row.original;
        console.log(row);
        return (
          <>
            <p>{row.stripe_id ? "Automated" : "To Invoice"}</p>
          </>
        );
      },
    },
    {
      accessor: "createdAt",
      Header: "Action",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <div className="t-action-btn">
              <button
                className="btn btn-info"
                onClick={() => handleView(row._id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>

              <button
                className="btn btn-success"
                onClick={() => {
                  if (row.company) {
                    const startDate = new Date(
                      row.company.companyPlanStartDate
                    );
                    const formattedStartDate = startDate
                      .toISOString()
                      .split("T")[0];

                    setSelectedPlanName(row.company.companyPlan);
                    setSelectedPlanPricePerUser(row.company.perUserPrice);
                    setSelectedPlanFrequency(row.company.planFrequency);
                    setSelectedPlanDate(formattedStartDate);
                    setUsedSeats(row.company.usedSeats);
                    setSelectedPlanSeats(row.company.seats);
                    setSelectedPlanFrequency(row.planFrequency ?? "monthly");
                  }

                  showSubscriptionPanel(row);
                  setSelectedUser(row._id);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={dataTableData}
        sortable
        pagination
        perPage={5}
      >
        <div
          style={{ borderRadius: "0.375rem" }}
          className="py-4 bg-white mb-3 d-flex align-items-center px-3"
        >
          <h5 className="hover-actions-trigger mb-0">Businesses & Groups</h5>
        </div>
        <Card className="mb-3">
          <Card.Header className="border-bottom border-200">
            <Row className="flex-between-center align-items-end g-2">
              <Col xs={8} sm="auto" className="ms-3 mt-2 text-end ps-0">
                <div id="orders-actions"></div>
              </Col>
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
          </Card.Header>
          <Row className="flex-end-center mb-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
          </Row>
        </Card>
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={dataTableData.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
      <Modal
        show={view}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Update a Business or Group</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Form noValidate onSubmit={handleSubmit(update_manager)}>
          <Modal.Body>
            <Form.Control type="hidden" id="id" name="id" {...register("id")} />

            <div className="vendor_logo_wrapper">
              <img
                src={fileDocList}
                className="img-fluid"
                style={{ height: "80px", width: "80px", borderRadius: "50" }}
              />
            </div>

            <Form.Group className="mb-3">
              <Form.Label>Firstname</Form.Label>
              <Form.Control
                type="text"
                id="firstname"
                name="firstname"
                placeholder="Enter firstname name"
                isInvalid={!!errors.firstname}
                {...register("firstname", {
                  required: "Firstname is Required",
                  pattern: {
                    value: /.*\S.*/,
                    message: "White space not allowed",
                  },
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Lastname</Form.Label>
              <Form.Control
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Enter Lastname name"
                isInvalid={!!errors.lastname}
                {...register("lastname", {
                  required: "lastname is Required",
                  pattern: {
                    value: /.*\S.*/,
                    message: "White space not allowed",
                  },
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>email</Form.Label>
              <Form.Control
                type="text"
                id="email"
                name="email"
                disabled
                placeholder="Enter email name"
                isInvalid={!!errors.email}
                {...register("email", {
                  required: "email is Required",
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                id="mobile"
                name="mobile"
                disabled
                placeholder="Enter mobile name"
                isInvalid={!!errors.mobile}
                {...register("mobile", {
                  required: "Mobile is Required",
                  minLength: {
                    value: 8,
                    message: "Minimum 8 digit is Required",
                  },
                  maxLength: {
                    value: 14,
                    message: "Maximum 14 digit is Required",
                  },
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Profile </Form.Label>
              <Form.Control
                type="file"
                name="profile_image"
                id="profile_image"
                isInvalid={!!errors.profile_image}
                onChange={(e) => handleChangeImageUpload(e)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showSubscription}
        onHide={handleSubscriptionClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Add/Update a Subscription</Modal.Title>
          <FalconCloseButton onClick={handleSubscriptionClose} />
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Control type="hidden" id="id" name="id" {...register("id")} />

            <Form.Group className="mb-3">
              <Form.Label>Plan</Form.Label>
              <Form.Select
                onChange={(value) => setSelectedPlanName(value.target.value)}
                value={selectedPlanName}
                name="package_name"
                aria-label="Default select example"
              >
                <option disabled>Select Plan</option>
                <option value="63e8d49d148d1035e10bf64e">Heart</option>
                <option value="63e8d4b8148d1035e10bf64f">Core</option>
                <option value="63e8d4df148d1035e10bf650">Expand</option>
                <option value="63e8d555148d1035e10bf651">Optimum</option>
              </Form.Select>

              <Form.Label className="mt-2">Active Seats</Form.Label>
              <Form.Control
                onChange={(value) => setSelectedPlanSeats(value.target.value)}
                value={selectedPlanSeats}
                name="seats"
              ></Form.Control>

              <Form.Label className="mt-2">Used Seats</Form.Label>
              <Form.Control
                onChange={(value) => setUsedSeats(value.target.value)}
                value={usedSeats}
                name="seats"
              ></Form.Control>

              <Form.Label className="mt-2">Price Per User</Form.Label>
              <Form.Control
                onChange={(value) =>
                  setSelectedPlanPricePerUser(value.target.value)
                }
                value={selectedPlanPricePerUser}
                name="price_per_user"
                type={"number"}
              ></Form.Control>

              <Form.Label className="mt-2">Subscription Start Date</Form.Label>
              <Form.Control
                onChange={(value) => setSelectedPlanDate(value.target.value)}
                value={selectedPlanDate}
                name="renewal_date"
                type={"date"}
              ></Form.Control>

              <Form.Label className="mt-2">Renewal Frequency</Form.Label>
              <Form.Select
                onChange={(value) =>
                  setSelectedPlanFrequency(value.target.value)
                }
                value={selectedPlanFrequency}
                name="plan_frequency"
                aria-label="Default select example"
              >
                <option disabled>Renewal Frequency</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSubscriptionClose}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={subscribe_to_plan}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    accessToken: state.Auth.accessToken,
  };
};
export default connect(mapStateToProps)(Businesses);
