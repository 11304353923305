import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Flex from "components/common/Flex";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DashboardService from "services/dashboard/dashboard";

import classNames from "classnames";

const TotalEatwell = ({ amountClassName }) => {
  const dispatch = useDispatch();
  const [eatwell, totalEatwell] = useState("");
  const getData = () => {
    dispatch(DashboardService.getData())
      .then((res) => {
        totalEatwell(res.data.eatwell);
      })
      .catch((errors) => {});
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Active Recipes</h6>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2
            className={classNames(
              amountClassName,
              "mb-1 text-700 fw-normal lh-1"
            )}
          >
            {eatwell}
          </h2>
        </div>
      </Card.Body>
    </Card>
  );
};

TotalEatwell.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.string,
  amountClassName: PropTypes.string,
};

export default TotalEatwell;
