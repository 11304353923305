import axios from "axios";
import store from "../../src/store";
import * as actions from "../../src/store/actions";
import ToastMe from "../../src/components/common/ToastMe";

const BaseUrl = process.env.REACT_APP_API_HOST;
function AxiosMiddleware(method, url, data, options) {
  switch (method) {
    case "get":
      return axios.get(url, { ...options, params: data });
    case "post":
      return axios.post(url, data, options);
    case "head":
      return axios.head(url, data, options);
    case "patch":
      return axios.patch(url, data, options);
    case "put":
      return axios.put(url, data, options);
    case "delete":
      return axios.delete(url, { ...options, params: data });
  }
}

axios.defaults.headers["env"] = "test";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem("token");
        store.dispatch(actions.authLogout());
        window.location.href = "/";
        break;
      case 404:
        ToastMe(error.response.data.message, "danger");
        break;

      default:
        console.log("error.response.status");
        console.log(error.response);
        break;
    }
    return Promise.reject(error);
  }
);

export function get(url, data = [], options = {}) {
  return AxiosMiddleware("get", url, data, options);
}
export function post(url, data = [], options = {}) {
  return AxiosMiddleware("post", url, data, options);
}
export function head(url, data = [], options = {}) {
  return AxiosMiddleware("head", url, data, options);
}
export function patch(url, data = [], options = {}) {
  return AxiosMiddleware("patch", url, data, options);
}
export function put(url, data = [], options = {}) {
  return AxiosMiddleware("put", url, data, options);
}
export function del(url, data = [], options = {}) {
  return AxiosMiddleware("delete", url, data, options);
}
export function setBearerToken(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
export function setVerifyToken(token) {
  axios.defaults.headers.common["VerifyToken"] = `${token}`;
}

// improvements
export function callApi(apiData, data = [], options = {}) {
  const method = apiData[0];
  const url = apiData[1];
  const fullUrl = BaseUrl + url;
  return AxiosMiddleware(method, fullUrl, data, options);
}

// improvements
export function callEndpoint(apiData, data = [], options = {}) {
  const method = apiData[0];
  const url = apiData[1];
  const fullUrl = BaseUrl + url;
  return AxiosMiddleware(method, fullUrl, data, options);
}
