import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import ToastMe from "../common/ToastMe";

import classNames from "classnames";

import { Card, Col, Row, ToastContainer, Toast } from "react-bootstrap";
import Flex from "../common/Flex";

import { connect, useDispatch } from "react-redux";

import IconButton from "components/common/IconButton";
import { toast } from "react-toastify";
import MindfulnessService from "services/mindfulness/mindfulness";
import VirtualCommuteItem from "./VirtualCommuteItem";
import AddVirtualCommute from "./Modals/AddVirtualCommute";
import EditVirtualCommute from "./Modals/EditVirtualCommute";

const VirtualCommute = () => {
  const dispatch = useDispatch();
  const [virtualCommutes, setVirtualCommutes] = useState([]);

  // New Variables & Cleaner States
  const [showAddMindfulness, setShowAddMindfulness] = useState(false);

  const [selectedMindfulness, setSelectedMindfulness] = useState(null);
  const [showEditMindfulness, setShowEditMindfulness] = useState(false);

  const [showAudio, setShowAudio] = useState(false);
  const [playAudio, setPlayAudio] = useState("");

  const handleAudioPlay = (event) => {
    setPlayAudio(event);
    setShowAudio(true);
  };

  const handleAudioStop = () => {
    setPlayAudio("");
    setShowAudio(false);
    virtualCommutes;
  };

  const editMindfulness = (data) => {
    // Set all details without the need to call another API
    // Data is already available
    setSelectedMindfulness(data);
    setShowEditMindfulness(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(MindfulnessService.getVirtualCommuteData())
      .then((res) => {
        setVirtualCommutes(res.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const addVirtualCommute = (data) => {
    dispatch(MindfulnessService.addVirtualCommute(data))
      .then((res) => {
        getData();
        handleClose();
      })
      .catch((errors) => {
        ToastMe(errors.data.message);
      });
  };

  const updateVirtualCommuteItem = (data) => {
    dispatch(MindfulnessService.updateVirtualCommuteItem(data))
      .then((res) => {
        setShowEditMindfulness(false);
        setSelectedMindfulness(null);
        getData();
      })
      .catch((errors) => {
        return toast.error(errors.data.message);
      });
  };

  //delete mindfulness
  const deleteVirtualCommuteItem = (id) => {
    Swal.fire({
      title: "Delete Virtual Commute Item",
      text: "Deleting a Virtual Commute Item is irreversible. Please confirm you want to delete this item.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete Virtual Commute Item",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          id: id,
        };
        dispatch(MindfulnessService.deleteVirtualCommuteItem(data))
          .then((res) => {
            getData();
          })
          .catch((errors) => {
            toast.error(errors.data.message);
          });
      }
    });
  };

  return (
    <>
      <AddVirtualCommute
        isOpen={showAddMindfulness}
        onClose={() => setShowAddMindfulness(false)}
        onSubmit={addVirtualCommute}
      />
      <EditVirtualCommute
        isOpen={showEditMindfulness}
        onClose={() => setShowEditMindfulness(false)}
        item={selectedMindfulness}
        onSubmit={updateVirtualCommuteItem}
      />

      <Card className="mb-3">
        <Card.Body>
          <Row className="flex-between-center">
            <Col
              sm="auto"
              as={Flex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
              className="mb-2 mb-sm-0"
            >
              <h5 style={{ fontWeight: 600 }}>Virtual Commute Items</h5>
              <p style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 300 }}>
                {virtualCommutes?.length ?? 0} Items
              </p>
            </Col>
            <Col sm="auto">
              <Row className="gx-2 align-items-center">
                <IconButton
                  onClick={() => setShowAddMindfulness(true)}
                  icon="plus"
                  transform="shrink-3"
                  className="btn btn-primary me-2 px-4"
                >
                  <span style={{ fontWeight: 600 }} className="ms-1">
                    Add
                  </span>
                </IconButton>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body
          className={classNames({
            "p-0 overflow-hidden": false,
            "pb-0": true,
          })}
        >
          <Row
            className={classNames({
              "g-0": false,
            })}
          >
            {virtualCommutes && virtualCommutes?.length > 0
              ? virtualCommutes.map((item) => (
                  <VirtualCommuteItem
                    key={item?._id ?? ""}
                    item={item}
                    isPlaying={playAudio}
                    onPlay={handleAudioPlay}
                    onPause={handleAudioStop}
                    onEdit={editMindfulness}
                    onDelete={deleteVirtualCommuteItem}
                  />
                ))
              : null}
          </Row>
        </Card.Body>
      </Card>

      <ToastContainer
        className="p-3 position-fixed"
        style={{ zIndex: "99" }}
        position={"bottom-end"}
      >
        <Toast onClose={handleAudioStop} show={showAudio} delay={3000}>
          <Toast.Body>
            <Toast.Header className="border-0 p-0 pe-2">
              <audio
                src={playAudio?.audio ?? ""}
                controls
                autoPlay
                preload="auto"
              />
            </Toast.Header>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

// export default Interest;
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    accessToken: state.Auth.accessToken,
  };
};
export default connect(mapStateToProps)(VirtualCommute);
