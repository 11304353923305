import { successResponse } from "../../../src/components/helpers/response";
import Http from "../../security/Http";
import * as action from "../../store/actions";
import ToastMe from "../../components/common/ToastMe";
import axios from "axios";
const BaseUrl = process.env.REACT_APP_API_HOST;

export function login(credentials) {
  credentials["username"] = {
    2: credentials.email,
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      console.log(credentials);
      axios
        .post(BaseUrl + "admin/login/", credentials, {
          headers: {
            "Content-Type": "application/json",
            env: "test",
          },
        })
        .then(function (res) {
          console.log(res);
          localStorage.setItem("token", res.data.accessToken);
          successResponse(res);
          dispatch(action.authLogin(res));
          return resolve(res);
        })
        .catch(function (error) {
          console.log(error);
          const data = {
            errorData: error.response.data.errors,
            statusCode: error.response.status,
          };
          return reject(data);
        });
    });
}

export function register(credentials) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.callApi("post", BaseUrl + "/registration", credentials)
        .then((res) => {
          ToastMe(res.data.message);
          dispatch(action.authLogin(res));
          return resolve(res);
        })
        .catch((err) => {
          ToastMe(err.response.data.message, "danger");
          const data = {
            errorData: err.response.data.errors,
            statusCode: err.response.status,
          };
          return reject(data);
        });
    });
}
