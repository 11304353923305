import React from "react";
import { Card } from "react-bootstrap";
import Flex from "components/common/Flex";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import DashboardService from "services/dashboard/dashboard";

const TotalEmployee = () => {
  const dispatch = useDispatch();
  const [employee, totalEmployee] = useState("");
  const getData = () => {
    dispatch(DashboardService.getData())
      .then((res) => {
        totalEmployee(res.data.employee);
      })
      .catch((errors) => {});
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Active People</h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-2"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{employee}</h2>
        </div>
        <div className="ps-0"></div>
      </Card.Body>
    </Card>
  );
};

export default TotalEmployee;
