import React from "react";
import Flex from "components/common/Flex";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPlayCircle,
  faStopCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

const MindfulnessItem = ({
  item,
  isPlaying,
  onPlay,
  onPause,
  onEdit,
  onDelete,
  onChangeStatus,
}) => {
  return (
    <>
      <Col md={3} className="mb-4">
        <Flex
          direction="column"
          justifyContent="between"
          className="border h-100 pb-3"
          style={{
            borderRadius: "8px",
          }}
        >
          <div className="overflow-hidden">
            {item?.image ? (
              <img
                src={item?.image}
                alt={item?.title}
                style={{
                  width: "100%",
                  height: "192px",
                  objectFit: "cover",
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              />
            ) : null}
            <div className="p-3">
              <h5 style={{ fontWeight: "500", fontSize: 16, color: "black" }}>
                {item?.title ?? ""}
              </h5>
              <p style={{ fontWeight: "300", color: "black" }}>
                {item?.play_duration ?? ""}
              </p>

              <p style={{ fontWeight: "400", color: "black" }}>
                {item?.description ?? ""}
              </p>
            </div>
          </div>
          <Flex alignItems="center" className="px-3">
            <OverlayTrigger
              placement="top"
              overlay={
                isPlaying !== item ? (
                  <Tooltip>Play Audio</Tooltip>
                ) : (
                  <Tooltip>Stop Audio</Tooltip>
                )
              }
            >
              {isPlaying !== item ? (
                <button
                  className="btn btn-primary"
                  onClick={() => onPlay(item)}
                >
                  <FontAwesomeIcon icon={faPlayCircle} />
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={() => onPause(item)}
                >
                  <FontAwesomeIcon icon={faStopCircle} />
                </button>
              )}
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Edit Item</Tooltip>}
            >
              <button
                className="btn btn-info"
                style={{ marginLeft: 8 }}
                onClick={() => onEdit(item)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Delete Item</Tooltip>}
            >
              <button
                className="btn btn-danger"
                style={{ marginLeft: 8 }}
                onClick={(e) => onDelete(item?._id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </OverlayTrigger>

            <div style={{ flex: 1 }} />

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {item?.visible ? "Disable Mindfulness" : "Enable Mindfulness"}
                </Tooltip>
              }
            >
              <button
                className="btn"
                style={{
                  marginLeft: 8,
                  backgroundColor: item?.visible ? "red" : "green",
                }}
                onClick={() => onChangeStatus(item)}
              >
                {item?.visible ? (
                  <IoMdClose size={20} color="#ffffff" />
                ) : (
                  <IoMdCheckmark size={20} color="#ffffff" />
                )}
              </button>
            </OverlayTrigger>
          </Flex>
        </Flex>
      </Col>
    </>
  );
};

export default MindfulnessItem;
