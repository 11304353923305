import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Modal, Row, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faStar,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "components/common/IconButton";
import FalconCloseButton from "components/common/FalconCloseButton";
import { useForm } from "react-hook-form";
import { isError } from "components/helpers/response";
import EapService from "../../services/eap/eap";
import EatwellService from "../../services/recipes/recipes";
import { toast } from "react-toastify";

import Swal from "sweetalert2";

const EAP = () => {
  const dispatch = useDispatch();
  const [dataTableData, setDataTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [getflag, setflag] = useState(0);
  const [images, imageStore] = useState([]);
  const [getimage, imageUpload] = useState("");
  const [fileDocList, setFileDocList] = useState();
  const [isLoading, setLoading] = useState(false);
  const [form] = FormAnt.useForm();
  const { TextArea } = Input;
  const handleClose = () => {
    var form = document.getElementById("createForm");
    form.reset();
    reset({ keepDirtyValues: true }, { keepIsValid: true });
    setShow(false);
  };
  const handleClose1 = () => {
    reset({ keepDirtyValues: true }, { keepIsValid: true });
    setView(false);
    var form = document.getElementById("updateForm");
    form.reset();
  };
  const {
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    isError(errors);
  });

  const handleShow = (data) => {
    setShow(true);
  };

  const handleView = (data) => {
    setView(true);
    if (data) {
      const img = `${data.image}`;
      setFileDocList(img);
      imageUpload(data.image);
      form.setFieldsValue({
        mobile: data.mobile,
        id: data._id,
        options: data.options,
      });
      var image1 = [];
      data.options.map((data) => {
        image1.push(data.image);
      });
      imageStore(image1);
    }
  };

  const updateEap = (data) => {
    if (getimage != "") {
      data["image"] = getimage;
    }
    if (images != "") {
      data.options.map((item, index) => {
        images.map((img, i) => {
          if (i == index) {
            item.image = img;
            delete item.image1;
          }
        });
      });
    }

    dispatch(EapService.addEap(data))
      .then((res) => {
        if (res.status === 200) {
          getData();
          handleClose1();
        }
      })
      .catch((errors) => {
        toast.error(errors.data.message);
      });
  };

  const addEap = (data) => {
    if (getimage != "") {
      data["image"] = getimage;
    }

    data["featured"] = featured;

    if (images != "") {
      images.map((items, i) => {
        data.options.map((item, index) => {
          if (i == index) {
            item.image = items;
          }
        });
      });
    }

    console.log(data);
    dispatch(EapService.addEap(data))
      .then((res) => {
        if (res.status === 200) {
          getData();
          handleClose();
        }
      })
      .catch((errors) => {
        toast.error(errors.data.message);
      });
  };
  const getData = () => {
    dispatch(EapService.getData())
      .then((res) => {
        console.log(res);
        setDataTableData(res.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  const remove1 = (i) => {
    images.splice(i, 1);
  };
  const handleChangeImageUpload = (e) => {
    var getData = e.target.id.split("_");
    var b = getData[1];
    var image_pre = e.target.files[0];
    setLoading(true);

    dispatch(EatwellService.upload_file(image_pre))
      .then((res) => {
        images[b] = res.data.file_url;
        imageStore(images);
        setLoading(false);
      })
      .catch((errors, statusCode) => {
        console.log(errors.data.message, "danger");
      });
  };

  const featuredSelected = (id) => {
    console.log("Set Featured: " + id);
    dispatch(
      EapService.featureSupportConnectProvider({
        id: id,
      })
    )
      .then((res) => {
        if (res.status === 200) {
          getData();
        }
      })
      .catch((errors) => {
        toast.error(errors.data.message);
      });
  };

  const handleFeaturedSelect = (event, index) => {
    console.log(event.target.checked);
    setFeatured(event.target.checked);
  };

  const handleImageUpload = (event, index) => {
    var image_pre = event.target.files[0];
    setLoading(true);

    // if (event.target.files[0].type.includes("image/") != true) {
    //   ToastMe("Please select only image");
    //   return true;
    // }
    dispatch(EatwellService.upload_file(image_pre))
      .then((res) => {
        if (res.status == "200") {
          setLoading(false);
          imageUpload(res.data.file_url);
        }
      })
      .catch((errors, statusCode) => {
        console.log(errors.data.message, "danger");
      });
  };
  useEffect(() => {
    getData();
  }, []);
  //datatable
  const columns = [
    {
      accessor: "_id",
      Header: "No",
      Cell: (rowData) => {
        return parseInt(rowData.row.id) + 1;
      },
    },

    {
      accessor: "featured",
      Header: "Featured",
      Cell: (rowData) => {
        const data = rowData.row.original;
        return <p style={{ color: "black" }}>{data.featured ? "Yes" : "No"}</p>;
      },
    },

    {
      accessor: "image",
      Header: "Image",
      Cell: (rowData) => {
        const data = rowData.row.original;
        return (
          <img
            src={`${data.image}`}
            className="profile_pic_img"
            style={{
              height: "80px",
              width: "80px",
              borderRadius: "50",
              objectFit: "contain",
            }}
            alt="profile"
          />
        );
      },
    },
    {
      accessor: "mobile",
      Header: "Mobile",
    },
    {
      accessor: "options",
      Header: "Options",
      Cell: (rowData) => {
        const data = rowData.row.original.options;
        const listItems = data?.map((item) => (
          <li>
            {item.image ? (
              <img
                src={`${item.image}`}
                className="profile_pic_img"
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50",
                  marginTop: "5px",
                }}
                alt="profile"
              />
            ) : (
              ""
            )}{" "}
            <br />
            <div style={{ "white-space": "normal", "max-width": "500px" }}>
              {item.title}
              {" - "}
              {item.description}
            </div>
          </li>
        ));
        return <ol type="1">{listItems}</ol>;
      },
    },
    {
      accessor: "createdAt",
      Header: "Action",
      Cell: (rowData, props) => {
        const row = rowData.row.original;
        return (
          <>
            <div className="t-action-btn">
              <button
                className="btn btn-warning"
                onClick={() => {
                  featuredSelected(row._id);
                }}
              >
                <FontAwesomeIcon icon={faStar} />
              </button>

              <button className="btn btn-info" onClick={() => handleView(row)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>

              <button
                className="btn btn-danger"
                onClick={(e) => deleteSupportConnectProvider(row._id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  // delete eatwell
  const deleteSupportConnectProvider = (id) => {
    Swal.fire({
      title: "Delete Support Connect Provider?",
      text: "Are you sure you want to delete this Support Connect Provider?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          id: id,
        };
        dispatch(EapService.deleteSupportConnectProvider(data))
          .then((res) => {
            getData();
          })
          .catch((errors) => {
            toast.error(errors.data.message);
          });
      }
    });
  };

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={dataTableData}
        sortable
        pagination
        perPage={10}
      >
        <div
          style={{ borderRadius: "0.375rem" }}
          className="py-4 bg-white mb-3 d-flex align-items-center px-3"
        >
          <h5 className="hover-actions-trigger mb-0">
            Support Connect Providers
          </h5>
        </div>
        <Card className="mb-3">
          <Card.Header className="border-bottom border-200">
            <Row className="flex-between-center align-items-end g-2">
              <Col xs={8} sm="auto" className="ms-3 mt-2 text-end ps-0">
                <div id="orders-actions">
                  <IconButton
                    onClick={handleShow}
                    icon="plus"
                    transform="shrink-3"
                    className="btn btn-primary me-2"
                  >
                    <span className="d-sm-inline-block ms-1">Add</span>
                  </IconButton>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </div>
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Add Support Connect Provider</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="col">
            <FormAnt
              form={form}
              onFinish={addEap}
              initialValues={{ options: [""] }}
              id="createForm"
            >
              <Form.Label>Featured</Form.Label>

              <FormAnt.Item name="featured">
                <Input
                  onChange={(e) => handleFeaturedSelect(e)}
                  type="checkbox"
                  className="float-start"
                />
              </FormAnt.Item>

              <Form.Label>Image</Form.Label>

              <FormAnt.Item name="image">
                <Input
                  onChange={(e) => handleImageUpload(e)}
                  type="file"
                  className="form-control"
                />
              </FormAnt.Item>
              <Form.Label>Phone Number</Form.Label>
              <FormAnt.Item
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Phone Number is Required",
                  },
                  { pattern: /[0-9]/, message: "Only Numbers are allowed" },
                  { min: 4, message: "Minimum 4 digits must be entered" },
                  { max: 15, message: "Maximum 15 digits must be entered" },
                ]}
              >
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Phone Number"
                />
              </FormAnt.Item>

              {/* <FormAnt.List name="options">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div className="recipe_desc_add_container">
                        <FormAnt.Item
                          {...restField}
                          label="Title"
                          name={[name, "title"]}
                          rules={[
                            { required: true, message: "Title is Required" },
                          ]}
                        >
                          <Input placeholder="Enter title" />
                        </FormAnt.Item>
                        <FormAnt.Item
                          {...restField}
                          label="Description"
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "Description is Required",
                            },
                          ]}
                        >
                          <Input placeholder="Enter description" />
                        </FormAnt.Item>
                        <FormAnt.Item label="Image" name={[name, "image"]}>
                          <Input
                            onChange={(e) => {
                              handleChangeImageUpload(e);
                            }}
                            type="file"
                            className="form-control"
                          />
                        </FormAnt.Item>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="recipe_desc_add_container_removeBtn"
                            onClick={() => {
                              remove(name);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    <FormAnt.Item>
                      <ButtonAnt
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add field
                      </ButtonAnt>
                    </FormAnt.Item>
                  </>
                )}
              </FormAnt.List> */}
              <FormAnt.List name="options">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, i) => (
                      <div className="recipe_desc_add_container">
                        <Form.Label>Title</Form.Label>
                        <FormAnt.Item
                          {...restField}
                          name={[name, "title"]}
                          rules={[
                            { required: true, message: "Title is Required " },
                            {
                              pattern: /.*\S.*/,
                              message: "White space not allowed",
                            },
                          ]}
                        >
                          <Input placeholder="Enter title" />
                        </FormAnt.Item>
                        <Form.Label>Description</Form.Label>
                        <FormAnt.Item
                          {...restField}
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "Description is Required ",
                            },
                            {
                              pattern: /.*\S.*/,
                              message: "White space not allowed",
                            },
                          ]}
                        >
                          <TextArea placeholder="Enter description" />
                        </FormAnt.Item>
                        <FormAnt.Item {...restField} name={[name, "image"]}>
                          <Input
                            onChange={(e) => {
                              handleChangeImageUpload(e);
                            }}
                            type="file"
                            className="form-control"
                          />
                        </FormAnt.Item>

                        {fields.length > 1 ? (
                          <></>
                        ) : (
                          // <MinusCircleOutlined
                          //   className="recipe_desc_add_container_removeBtn"
                          //   onClick={(e) => {
                          //     remove(name);
                          //   }}
                          // />
                          ""
                        )}
                      </div>
                    ))}

                    <FormAnt.Item>
                      <ButtonAnt
                        type="dashed"
                        onClick={() => add()}
                        block
                        // icon={<PlusOutlined />}
                      >
                        Add field
                      </ButtonAnt>
                    </FormAnt.Item>
                  </>
                )}
              </FormAnt.List>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Uploading…" : "Submit"}
                </Button>
              </Modal.Footer>
            </FormAnt>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={view}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Update Support Connect Provider</Modal.Title>
          <FalconCloseButton onClick={handleClose1} />
        </Modal.Header>
        <Modal.Body>
          <div className="col">
            <div className="vendor_logo_wrapper">
              <img
                src={fileDocList}
                className="img-fluid"
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "50",
                }}
                alt="Provider Logo"
              />
            </div>
            <FormAnt
              initialValues={{ options: [""] }}
              form={form}
              onFinish={updateEap}
              id="updateForm"
            >
              <FormAnt.Item name="id">
                <Input type="hidden" />
              </FormAnt.Item>

              <FormAnt.Item name="image">
                <Input
                  onChange={(e) => handleImageUpload(e)}
                  type="file"
                  className="form-control"
                />
              </FormAnt.Item>
              <FormAnt.Item
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Mobile is Required",
                  },
                  { pattern: /[0-9]/, message: "Only Numbers are allowed" },
                  { min: 4, message: "Minimum 4 digits must be entered" },
                  { max: 15, message: "Maximum 15 digits must be entered" },
                ]}
              >
                <Input
                  type="number"
                  className="form-control"
                  placeholder="Enter Phone Number"
                />
              </FormAnt.Item>

              <FormAnt.List name="options">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, i) => (
                      <div className="recipe_desc_add_container">
                        <Form.Label>Title</Form.Label>
                        <FormAnt.Item
                          {...restField}
                          name={[name, "title"]}
                          rules={[
                            { required: true, message: "Title is Required " },
                            {
                              pattern: /.*\S.*/,
                              message: "White space not allowed",
                            },
                          ]}
                        >
                          <Input placeholder="Enter title" />
                        </FormAnt.Item>
                        <Form.Label>Description</Form.Label>
                        <FormAnt.Item
                          {...restField}
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "Description is Required ",
                            },
                            {
                              pattern: /.*\S.*/,
                              message: "White space not allowed",
                            },
                          ]}
                        >
                          <TextArea placeholder="Enter description" />
                        </FormAnt.Item>
                        <FormAnt.Item {...restField} name={[name, "image1"]}>
                          <Input
                            onChange={(e) => {
                              handleChangeImageUpload(e);
                            }}
                            type="file"
                            className="form-control"
                          />
                        </FormAnt.Item>
                        {images?.[i] ? (
                          <div className="vendor_logo_wrapper">
                            <img
                              src={images?.[i]}
                              className="img-fluid"
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50",
                              }}
                              alt="Provider Logo"
                            />
                          </div>
                        ) : (
                          ""
                        )}{" "}
                        {fields.length > 1 ? (
                          <></>
                        ) : (
                          // <MinusCircleOutlined
                          //   className="recipe_desc_add_container_removeBtn"
                          //   onClick={(e) => {
                          //     remove(name);
                          //     remove1(i);
                          //   }}
                          // />
                          ""
                        )}
                      </div>
                    ))}

                    <FormAnt.Item>
                      <ButtonAnt
                        type="dashed"
                        onClick={() => add()}
                        block
                        // icon={<PlusOutlined />}
                      >
                        Add field
                      </ButtonAnt>
                    </FormAnt.Item>
                  </>
                )}
              </FormAnt.List>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                  Close
                </Button>

                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Uploading…" : "Submit"}
                </Button>
              </Modal.Footer>
            </FormAnt>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// export default Interest;
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    accessToken: state.Auth.accessToken,
  };
};
export default connect(mapStateToProps)(EAP);
