import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";

import ErrorLayout from "./ErrorLayout";
import { toast, ToastContainer } from "react-toastify";
import { CloseButton } from "components/common/Toast";

import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

import Login from "components/Login/Login";

import Dashboard from "components/Dashboard";
import Exercises from "components/Exercises/Exercises";
import Recipes from "components/Recipes/Recipes";
import Eap from "components/SupportProviders/SupportProviders";
import Mindfulness from "components/Mindfulness/Mindfulness";
import Businesses from "components/Businesses/Businesses";
import VirtualCommute from "components/VirtualCommute/VirtualCommute";
import PageTitlesEditor from "components/PageTitles/PageTitles";
import Users from "components/Users/Users";

const Layout = () => {
  // const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  // useContext(AppContext);

  // useEffect(() => {
  //   if (is.windows()) {
  //     HTMLClassList.add("windows");
  //   }
  //   if (is.chrome()) {
  //     HTMLClassList.add("chrome");
  //   }
  //   if (is.firefox()) {
  //     HTMLClassList.add("firefox");
  //   }
  // }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />

        <Route path="login" element={<Login />} />
        <Route path="/" element={<Login />} />

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/recipes" element={<Recipes />} />
          <Route path="/admin/exercise-routines" element={<Exercises />} />
          <Route path="/admin/support-connect" element={<Eap />} />
          <Route path="/admin/mindfulness" element={<Mindfulness />} />
          <Route path="/admin/virtual-commute" element={<VirtualCommute />} />
          <Route path="/admin/page-titles" element={<PageTitlesEditor />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/businesses" element={<Businesses />} />
        </Route>
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};

export default Layout;
