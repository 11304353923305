import React from "react";
import Flex from "components/common/Flex";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { IoMdClose, IoMdCheckmark } from "react-icons/io";

const ExerciseItem = ({ item, onEdit, onDelete, onChangeStatus }) => {
  return (
    <>
      <Col md={3} className="mb-4">
        <Flex
          direction="column"
          justifyContent="between"
          className="border h-100 pb-3"
          style={{
            borderRadius: "8px",
          }}
        >
          <div className="overflow-hidden">
            {item?.image ? (
              <img
                src={item?.image}
                alt={item?.title}
                style={{
                  width: "100%",
                  height: "192px",
                  objectFit: "cover",
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              />
            ) : null}
            <div className="p-3">
              <h5 style={{ fontWeight: "500", fontSize: 16, color: "black" }}>
                {item?.title ?? ""}
              </h5>

              <p style={{ fontWeight: "400", color: "black" }}>
                Visible:{" "}
                <span style={{ color: item?.visible ? "green" : "red" }}>
                  {item?.visible ? "Yes" : "No"}
                </span>
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Edit Exercise</Tooltip>}
            >
              <button className="btn btn-info" onClick={() => onEdit(item)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Delete Exercise</Tooltip>}
            >
              <button
                className="btn btn-danger"
                style={{ marginLeft: 8 }}
                onClick={(e) => onDelete(item?._id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </OverlayTrigger>

            <div style={{ flex: 1 }} />

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {item?.visible ? "Disable Exercise" : "Enable Exercise"}
                </Tooltip>
              }
            >
              <button
                className="btn"
                style={{
                  marginLeft: 8,
                  backgroundColor: item?.visible ? "red" : "green",
                }}
                onClick={() => onChangeStatus(item)}
              >
                {item?.visible ? (
                  <IoMdClose size={20} color="#ffffff" />
                ) : (
                  <IoMdCheckmark size={20} color="#ffffff" />
                )}
              </button>
            </OverlayTrigger>
          </div>
        </Flex>
      </Col>
    </>
  );
};

export default ExerciseItem;
