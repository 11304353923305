import React from "react";
import LoginForm from "components/Login/LoginForm";

const Login = () => (
  <>
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          flex: 1,
          width: "50vw",
          height: "100vh",
          position: "relative",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={require("../../assets/images/login_sidebar.webp")}
        />

        <div
          style={{
            position: "absolute",
            bottom: 32,
            left: 32,
            right: 32,
            zIndex: 10,
          }}
        >
          <h2 style={{ color: "#ffffff", fontSize: 28, fontWeight: "600" }}>
            Be Proactive with Health and Wellbeing
          </h2>
          <p style={{ color: "#ffffff", fontSize: 18, fontWeight: "500" }}>
            Login to control the Mind Pulse Platform
          </p>
        </div>
      </div>

      <div
        style={{
          flex: 1,
          width: "50vw",
          height: "100vh",
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "30%", objectFit: "contain" }}
            src={require("../../assets/images/appicon.png")}
          />
          <div style={{ width: "65%" }}>
            <h5 style={{ fontWeight: "600", fontSize: 20 }}>
              Administration Panel
            </h5>

            <p style={{ fontWeight: "300", marginBottom: 12 }}>
              Login to control, and update information on the Mind Pulse
              Platform.
            </p>
            <LoginForm hasLabel={true} />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Login;
