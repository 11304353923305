import React from "react";

const CommonTextField = ({
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  marginBottom = 12,
  multiline = false,
}) => {
  return (
    <>
      <div style={{ marginBottom: marginBottom }}>
        <p
          style={{
            paddingBottom: 0,
            marginBottom: 4,
            fontWeight: "600",
            color: "#181818",
            fontSize: 14,
          }}
        >
          {label}
        </p>
        {!multiline ? (
          <input
            style={{
              width: "100%",
              borderWidth: 1,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              borderRadius: 8,
            }}
            type={type}
            value={value}
            placeholder={placeholder ?? ""}
            onChange={onChange}
          />
        ) : (
          <textarea
            style={{
              width: "100%",
              borderWidth: 1,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              borderRadius: 8,
            }}
            type={type}
            value={value}
            placeholder={placeholder ?? ""}
            onChange={onChange}
          />
        )}
      </div>
    </>
  );
};

export default CommonTextField;
