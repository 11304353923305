import React from "react";
import { useDropzone } from "react-dropzone";

import { FaUpload, FaRegHandPaper } from "react-icons/fa";

const CommonDropzone = ({ dropFile }) => {
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    dropFile(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          width: "100%",
          border: "2px dashed gray",
          padding: "20px",
          borderRadius: 8,
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            <FaRegHandPaper size={28} color="#181818" />
            <p
              style={{
                paddingBottom: 0,
                marginBottom: 0,
                fontSize: 14,
                fontWeight: "600",
                marginTop: 12,
              }}
            >
              Release the file to upload!
            </p>
          </>
        ) : (
          <>
            <FaUpload size={28} color="#181818" />
            <p
              style={{
                paddingBottom: 0,
                marginBottom: 0,
                fontSize: 14,
                fontWeight: "600",
                marginTop: 12,
              }}
            >
              Drag & drop a file here, or click to select
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default CommonDropzone;
