import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import ToastMe from "../common/ToastMe";

import classNames from "classnames";

import { Card, Col, Row } from "react-bootstrap";
import Flex from "../common/Flex";

import { connect, useDispatch } from "react-redux";

import IconButton from "components/common/IconButton";
import { toast } from "react-toastify";
import RecipeItem from "./RecipeItem";
import RecipeService from "services/recipes/recipes";

const Recipes = () => {
  const dispatch = useDispatch();
  const [recipes, setRecipes] = useState([]);

  // New Variables & Cleaner States
  const [showAddRecipe, setShowAddRecipe] = useState(false);

  const [selectedMindfulness, setSelectedMindfulness] = useState(null);
  const [showEditMindfulness, setShowEditMindfulness] = useState(false);

  const editMindfulness = (data) => {
    // Set all details without the need to call another API
    // Data is already available
    setSelectedMindfulness(data);
    setShowEditMindfulness(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(RecipeService.getData())
      .then((res) => {
        console.log("Recipe Response: ", res);
        setRecipes(res.data.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const addMindfulness = (data) => {
    dispatch(MindfulnessService.addMindfulness(data))
      .then((res) => {
        getData();
        handleClose();
      })
      .catch((errors) => {
        ToastMe(errors.data.message);
      });
  };

  const updateRecipeAvailability = (data) => {
    dispatch(
      RecipeService.changeRecipeAvailability({
        id: data?._id,
      })
    )
      .then((res) => {
        getData();
      })
      .catch((errors) => {
        return toast.error(errors.data.message);
      });
  };

  const updateMindfulness = (data) => {
    dispatch(MindfulnessService.updateMindfulnessItem(data))
      .then((res) => {
        setShowEditMindfulness(false);
        setSelectedMindfulness(null);
        getData();
      })
      .catch((errors) => {
        return toast.error(errors.data.message);
      });
  };

  const deleteMindfulness = (id) => {
    Swal.fire({
      title: "Delete Recipe",
      text: "Deleting a Recipe is irreversible. Please confirm you want to delete this item.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete Recipe",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          id: id,
        };
        dispatch(MindfulnessService.deleteMindfulnessItem(data))
          .then((res) => {
            getData();
          })
          .catch((errors) => {
            toast.error(errors.data.message);
          });
      }
    });
  };

  const totalRecipes = recipes.reduce(
    (acc, type) => acc + (type.recipes ? type.recipes.length : 0),
    0
  );

  return (
    <>
      {/* <AddMindfulness
        isOpen={showAddMindfulness}
        onClose={() => setShowAddMindfulness(false)}
        onSubmit={addMindfulness}
      />
      <EditMindfulness
        isOpen={showEditMindfulness}
        onClose={() => setShowEditMindfulness(false)}
        item={selectedMindfulness}
        onSubmit={updateMindfulness}
      /> */}
      <Card className="mb-3">
        <Card.Body>
          <Row className="flex-between-center">
            <Col
              sm="auto"
              as={Flex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
              className="mb-2 mb-sm-0"
            >
              <h5 style={{ fontWeight: 600 }}>Recipes</h5>
              <p style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 300 }}>
                {recipes?.length ?? 0} Categories, {totalRecipes} Recipes
              </p>
            </Col>
            <Col sm="auto">
              <Row className="gx-2 align-items-center">
                <IconButton
                  onClick={() => setShowAddMindfulness(true)}
                  icon="plus"
                  transform="shrink-3"
                  className="btn btn-primary me-2 px-4"
                >
                  <span style={{ fontWeight: 600 }} className="ms-1">
                    Add
                  </span>
                </IconButton>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {recipes && recipes?.length > 0
        ? recipes?.map((item) => (
            <>
              <Card className="mb-3">
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col
                      sm="auto"
                      as={Flex}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                      className="mb-2 mb-sm-0"
                    >
                      <h5 style={{ fontWeight: 600 }}>{item?.name}</h5>
                      <p
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                          fontWeight: 300,
                        }}
                      >
                        {item?.recipes?.length ?? 0} Recipes
                      </p>
                    </Col>
                  </Row>
                  <Row
                    style={{ marginTop: 24 }}
                    className={classNames({
                      "g-0": false,
                    })}
                  >
                    {item?.recipes?.map((recipe) => (
                      <RecipeItem
                        key={recipe.id}
                        item={recipe}
                        onEdit={editMindfulness}
                        onDelete={deleteMindfulness}
                        onChangeStatus={(item) =>
                          updateRecipeAvailability(item)
                        }
                      />
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </>
          ))
        : null}
    </>
  );
};

// export default Interest;
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    accessToken: state.Auth.accessToken,
  };
};
export default connect(mapStateToProps)(Recipes);
