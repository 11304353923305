import CommonDropzone from "components/common/CommonDropzone";
import CommonTextField from "components/common/CommonTextField";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { IoCloseOutline } from "react-icons/io5";

import { useDispatch } from "react-redux";
import MindfulnessService from "services/mindfulness/mindfulness";

const EditVirtualCommute = ({ isOpen, onClose, item, onSubmit }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [audioURL, setAudioURL] = useState("");
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setDescription(item.description);
      setAudioURL(item.audio);
      setImageURL(item.image);
    }
  }, [item]);

  const updateItem = () => {
    const data = {
      id: item?._id,
      title: title,
      description: description,
      audio: audioURL,
      image: imageURL,
    };

    console.log("Data Sent is: ", data);

    onSubmit(data);
  };

  const uploadImage = (file) => {
    dispatch(MindfulnessService.uploadMindfulnessImage(file))
      .then((res) => {
        console.log("Image Upload URL: ", res);
        if (res.status == "200") {
          setImageURL(res.data.file_url);
        }
      })
      .catch((errors, statusCode) => {
        console.log(errors);
      });
  };

  const uploadAudio = (file) => {
    dispatch(MindfulnessService.uploadMindfulnessAudio(file))
      .then((res) => {
        console.log("Audio Upload URL: ", res);
        if (res.status == "200") {
          setAudioURL(res.data.file_url);
        }
      })
      .catch((errors, statusCode) => {
        console.log(errors);
      });
  };

  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Modal.Title style={{ fontWeight: "600", fontSize: 20 }}>
            Edit Virtual Commute Item
          </Modal.Title>
          <p
            style={{
              marginBottom: 0,
              paddingBottom: 0,
              fontWeight: "400",
              fontSize: 15,
            }}
          >
            {item?.title ?? ""}
          </p>
        </div>

        <div onClick={onClose} style={{ cursor: "pointer" }}>
          <IoCloseOutline size={24} />
        </div>
      </Modal.Header>

      <Modal.Body>
        {/* Input Group */}
        <CommonTextField
          label={"Virtual Commute Title"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={"Virtual Commute Title"}
        />

        <CommonTextField
          label={"Virtual Commute Description"}
          value={description}
          multiline={true}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={"Virtual Commute Description"}
        />

        {/* Audio Upload Area */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p
            style={{
              paddingBottom: 0,
              marginBottom: 4,
              fontWeight: "600",
              color: "#181818",
              fontSize: 14,
            }}
          >
            Audio
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 16,
            }}
          >
            {audioURL ? (
              <audio
                src={audioURL}
                style={{ width: "100%", marginRight: 12 }}
                controls
              />
            ) : null}
          </div>
          <CommonDropzone value={imageURL} dropFile={uploadAudio} />
        </div>

        {/* Image Upload Area */}
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 12 }}
        >
          <p
            style={{
              paddingBottom: 0,
              marginBottom: 4,
              fontWeight: "600",
              color: "#181818",
              fontSize: 14,
            }}
          >
            Image
          </p>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 16 }}
          >
            {imageURL ? (
              <img
                src={imageURL}
                alt="Mindfulness Upload"
                style={{
                  width: "40%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 8,
                  marginRight: 12,
                }}
              />
            ) : null}

            <CommonDropzone
              title={"Image"}
              value={imageURL}
              dropFile={uploadImage}
            />
          </div>
        </div>

        <p
          style={{
            paddingBottom: 12,
            marginBottom: 4,
            fontWeight: "400",
            color: "#181818",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          The Play Duration is automatically calculated based on the audio
          uploaded.
        </p>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="secondary"
            style={{ width: "48%", fontSize: 14, borderRadius: 8 }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <div style={{ flex: 1 }} />

          <Button
            variant="primary"
            onClick={updateItem}
            style={{ width: "48%", fontSize: 14, borderRadius: 8 }}
            type="submit"
          >
            Update VC Item
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditVirtualCommute;
