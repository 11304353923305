import React from "react";
import TotalEatwell from "./TotalEatwell";
import { Row, Col } from "react-bootstrap";

import TotalManager from "./TotalManager";
import TotalExersise from "./TotalExersise";
import TotalEmployee from "./TotalEmployee";
import MoodPulse from "./MoodPulse";

const Dashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={6}>
          <MoodPulse />
        </Col>
        <Col md={6} xxl={6}>
          <Row className="g-3 mb-3">
            <Col md={6} xxl={6}>
              <TotalManager />
            </Col>
            <Col md={6} xxl={6}>
              <TotalEmployee />
            </Col>
            <Col md={6} xxl={6}>
              <TotalExersise />
            </Col>
            <Col md={6} xxl={6}>
              <TotalEatwell />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={12}></Col>
      </Row>
    </>
  );
};

export default Dashboard;
