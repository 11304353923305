import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import ToastMe from "../common/ToastMe";

import classNames from "classnames";

import { Card, Col, Row, ToastContainer, Toast } from "react-bootstrap";
import Flex from "../common/Flex";

import { connect, useDispatch } from "react-redux";

import IconButton from "components/common/IconButton";
import { toast } from "react-toastify";
import MindfulnessService from "services/mindfulness/mindfulness";
import MindfulnessItem from "./MindfulnessItem";
import EditMindfulness from "./Modals/EditMindfulness";
import AddMindfulness from "./Modals/AddMindfulness";

const Mindfulness = () => {
  const dispatch = useDispatch();
  const [mindfulness, setMindfulness] = useState([]);

  // New Variables & Cleaner States
  const [showAddMindfulness, setShowAddMindfulness] = useState(false);

  const [selectedMindfulness, setSelectedMindfulness] = useState(null);
  const [showEditMindfulness, setShowEditMindfulness] = useState(false);

  const [showAudio, setShowAudio] = useState(false);
  const [playAudio, setPlayAudio] = useState("");

  const handleAudioPlay = (event) => {
    setPlayAudio(event);
    setShowAudio(true);
  };

  const handleAudioStop = () => {
    setPlayAudio("");
    setShowAudio(false);
  };

  const editMindfulness = (data) => {
    // Set all details without the need to call another API
    // Data is already available
    setSelectedMindfulness(data);
    setShowEditMindfulness(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(MindfulnessService.getMindfulnessItems())
      .then((res) => {
        setMindfulness(res.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const addMindfulness = (data) => {
    dispatch(MindfulnessService.addMindfulness(data))
      .then((res) => {
        getData();
        handleClose();
      })
      .catch((errors) => {
        ToastMe(errors.data.message);
      });
  };

  const updateMindfulness = (data) => {
    dispatch(MindfulnessService.updateMindfulnessItem(data))
      .then((res) => {
        setShowEditMindfulness(false);
        setSelectedMindfulness(null);
        getData();
      })
      .catch((errors) => {
        return toast.error(errors.data.message);
      });
  };

  //delete mindfulness
  const deleteMindfulness = (id) => {
    Swal.fire({
      title: "Delete Mindfulness Item",
      text: "Deleting a Mindfulness Item is irreversible. Please confirm you want to delete this item.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete Mindfulness Item",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          id: id,
        };
        dispatch(MindfulnessService.deleteMindfulnessItem(data))
          .then((res) => {
            getData();
          })
          .catch((errors) => {
            toast.error(errors.data.message);
          });
      }
    });
  };

  return (
    <>
      <AddMindfulness
        isOpen={showAddMindfulness}
        onClose={() => setShowAddMindfulness(false)}
        onSubmit={addMindfulness}
      />
      <EditMindfulness
        isOpen={showEditMindfulness}
        onClose={() => setShowEditMindfulness(false)}
        item={selectedMindfulness}
        onSubmit={updateMindfulness}
      />
      <Card className="mb-3">
        <Card.Body>
          <Row className="flex-between-center">
            <Col
              sm="auto"
              as={Flex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
              className="mb-2 mb-sm-0"
            >
              <h5 style={{ fontWeight: 600 }}>Mindfulness Items</h5>
              <p style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 300 }}>
                {mindfulness?.length ?? 0} Items
              </p>
            </Col>
            <Col sm="auto">
              <Row className="gx-2 align-items-center">
                <IconButton
                  onClick={() => setShowAddMindfulness(true)}
                  icon="plus"
                  transform="shrink-3"
                  className="btn btn-primary me-2 px-4"
                >
                  <span style={{ fontWeight: 600 }} className="ms-1">
                    Add
                  </span>
                </IconButton>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body
          className={classNames({
            "p-0 overflow-hidden": false,
            "pb-0": true,
          })}
        >
          <Row
            className={classNames({
              "g-0": false,
            })}
          >
            {mindfulness && mindfulness.length > 0
              ? mindfulness.map((item) => (
                  <MindfulnessItem
                    key={item?._id ?? ""}
                    item={item}
                    isPlaying={playAudio}
                    onPlay={handleAudioPlay}
                    onPause={handleAudioStop}
                    onEdit={editMindfulness}
                    onDelete={deleteMindfulness}
                  />
                ))
              : null}
          </Row>
        </Card.Body>
      </Card>

      <ToastContainer
        className="p-3 position-fixed"
        style={{ zIndex: "99" }}
        position={"bottom-end"}
      >
        <Toast onClose={handleAudioStop} show={showAudio} delay={3000}>
          <Toast.Body>
            <Toast.Header className="border-0 p-0 pe-2">
              <audio
                src={playAudio?.audio ?? ""}
                controls
                autoPlay
                preload="auto"
              />
            </Toast.Header>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

// export default Interest;
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    accessToken: state.Auth.accessToken,
  };
};
export default connect(mapStateToProps)(Mindfulness);
