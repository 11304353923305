import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import imgMoodHappy from "../../assets/images/moods/Happy.png";
import imgMoodSad from "../../assets/images/moods/Sad.png";
import imgMoodNeutral from "../../assets/images/moods/Neutral.png";
import imgMoodExcited from "../../assets/images/moods/Excited.png";
import imgMoodUnHappy from "../../assets/images/moods/Unhappy.png";
import { connect, useDispatch } from "react-redux";
import DashboardService from "services/dashboard/dashboard";

function MoodPulse() {
  const [type1, setType1] = useState("0%");
  const [type2, setType2] = useState("0%");
  const [type3, setType3] = useState("0%");
  const [type4, setType4] = useState("0%");
  const [type5, setType5] = useState("0%");
  const [time, setTime] = useState(new Date());

  const dispatch = useDispatch();
  const moodPercentage = () => {
    dispatch(DashboardService.moodPercentage())
      .then((res) => {
        res.data.map((data) => {
          if (data._id.mood_type == 1) {
            setType1(data.percentage);
          }
          if (data._id.mood_type == 2) {
            setType2(data.percentage);
          }
          if (data._id.mood_type == 3) {
            setType3(data.percentage);
          }
          if (data._id.mood_type == 4) {
            setType4(data.percentage);
          }
          if (data._id.mood_type == 5) {
            setType5(data.percentage);
          }
        });
      })
      .catch((errors) => {});
  };
  useEffect(() => {
    moodPercentage();
  }, []);

  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 1000);
  }, []);

  return (
    <Card className="h-md-100">
      <Card.Header className="">
        <div className="text-end">
          <span style={{ fontSize: "14px" }} className="me-3">
            <b>System Date : </b> {time.toLocaleDateString("en-AU")}
          </span>
          <span style={{ fontSize: "14px" }} className="">
            <b>System Time : </b>{" "}
            {time.toLocaleString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
        </div>
      </Card.Header>

      <Card.Body className="pt-0">
        <div className="mood_flex_row d-flex align-items-center gap-3 mb-0">
          <div classsName="mood_flex_item">
            <h6 className="text-center mb-2">Sad</h6>
            <img src={imgMoodSad} className="img-fluid mb-2" alt="" />
            <p className="text-center mb-0">{type4}</p>
          </div>

          <div classsName="mood_flex_item">
            <h6 className="text-center mb-2">Unhappy</h6>
            <img
              src={imgMoodUnHappy}
              className="img-fluid mb-2"
              alt=""
              height={192}
              width={192}
            />
            <p className="text-center mb-0">{type2}</p>
          </div>

          <div classsName="mood_flex_item">
            <h6 className="text-center mb-2">Ok</h6>
            <img src={imgMoodNeutral} className="img-fluid mb-2" alt="" />
            <p className="text-center mb-0">{type1}</p>
          </div>

          <div classsName="mood_flex_item">
            <h6 className="text-center mb-2">Happy</h6>
            <img src={imgMoodHappy} className="img-fluid mb-2" alt="" />
            <p className="text-center mb-0">{type3}</p>
          </div>

          <div classsName="mood_flex_item">
            <h6 className="text-center mb-2">Elated</h6>
            <img src={imgMoodExcited} className="img-fluid mb-2" alt="" />
            <p className="text-center mb-0">{type5}</p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default MoodPulse;
